import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  CircularProgress,
  ThemeProvider,
  Box,
  Typography,
  Paper,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { configFetch } from '../utils/api';
import Survey from './Survey';
import Success from './Success';
import getTheme from '../utils/theme';
import { getLanguage, getUrlParamsObject } from '../utils/helpers';

const setHTMLLanguage = (val) => {
  const htmlTag = document.querySelector('html');
  if (htmlTag) {
    htmlTag.setAttribute('lang', val);
  }
};

const Wrapper = () => {
  const [config, setConfig] = useState();
  const [error, setError] = useState();
  const location = useLocation();
  const query = getUrlParamsObject(
    new URLSearchParams(location.search).entries(),
  );

  const lang = getLanguage(query, config);
  if (config) {
    setHTMLLanguage(lang);
  }

  const fetchConfig = useCallback(async () => {
    try {
      const { data } = (
        await configFetch(encodeURIComponent(location.pathname.slice(1)), {
          lang,
          user: query.user,
        })
      ).data;
      setConfig({ id: data.id, ...data.attributes });
    } catch (e) {
      setError(e);
    }
  }, [lang, location.pathname, query.user]);

  useEffect(() => {
    if (location.pathname) {
      fetchConfig();
    }
  }, [fetchConfig, location.pathname]);

  if (!config) {
    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {error ? (
          <Paper elevation={3}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              p={4}
            >
              <Error color="error" style={{ fontSize: 60 }} />
              <Typography variant="h4" color="primary">
                {'' || 'There was an error getting the survey'}
              </Typography>
              <code>{error?.message}</code>
            </Box>
          </Paper>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  return (
    <ThemeProvider theme={getTheme(config)}>
      {config.answeredInfo ? (
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Success
            values={{ score: config.answeredInfo.score }}
            translations={config.survey.translations}
            config={config}
            lang={lang}
          />
        </Box>
      ) : (
        <Survey config={config} />
      )}
    </ThemeProvider>
  );
};

export default Wrapper;
