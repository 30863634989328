const feedbackAPIDefault =
  process.env.NODE_ENV === 'production'
    ? 'https://lumoa-feedback-api-stage.azurewebsites.net'
    : 'https://test-feedback-api.lumoa.me';

export const surveyAPIDefault =
  process.env.NODE_ENV === 'production' ? '' : 'https://test-api.lumoa.me';

export const feedbackAPI =
  process.env.REACT_APP_FEEDBACK_HOST || feedbackAPIDefault;

export const surveyAPI = process.env.REACT_APP_SURVEY_HOST || surveyAPIDefault;

export const apiVersion = process.env.REACT_APP_API_VERSION || 'v1';

export const lumoaUiHost =
  process.env.REACT_APP_DASHBOARD_URL || 'https://test.lumoa.me';

export default { feedbackAPI, surveyAPI, apiVersion };
