import { useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Link,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { Score, Text, Select, ContactRequest } from './fields';
import {
  getScoreField,
  getCommentField,
  getUrlParamsObject,
  getLanguage,
} from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  contactRequestContainer: {
    marginBottom: '20px',
  },
  submitButton: {
    color: theme.palette.buttonFont.primary,
    backgroundColor: theme.palette.button.primary,
    '&:hover': {
      backgroundColor: darken(theme.palette.button.primary, 0.2),
    },
  },
}));

const extractTags = (query, scoreField) => {
  const tagsResult = {};
  if (scoreField && query.score) {
    tagsResult[scoreField.key] = query.score;
  }
  Object.keys(query).forEach((key) => {
    if (['lang', 'ld'].includes(query[key])) return;
    tagsResult[key] = query[key];
  });
  return tagsResult;
};

const Form = ({ loading, translations, onSend, config }) => {
  const classes = useStyles();
  const location = useLocation();
  const [errors, setErrors] = useState({});
  const query = getUrlParamsObject(
    new URLSearchParams(location.search).entries(),
  );
  const scoreField = getScoreField(config);
  const commentField = getCommentField(config);

  const [data, setData] = useState({
    ...extractTags(query, scoreField),
  });
  const [pageIndex /* , setPageIndex */] = useState(0);
  const findTranslations = (f) => {
    return translations.pages[pages[pageIndex].key].fields[f.key];
  };

  const pages = config.survey.pages.sort((a, b) => a.order - b.order);
  const { fields } = pages[pageIndex];
  const fieldsArray = Object.keys(fields).map((f) => {
    const field = fields[f];
    return { ...field, translations: findTranslations(field) };
  });

  const lang = getLanguage(query, config);
  const hasPrivacyPolicy =
    config?.survey?.settings?.privacyPolicy &&
    config.survey.settings.privacyPolicy[lang];
  const hasContactRequest =
    config?.survey?.settings?.contact_request &&
    config.survey.settings.contact_request[lang];
  const handleSend = (e) => {
    e.preventDefault();
    const validationErrors = {};
    pages.forEach((page) => {
      page.fields.forEach((field) => {
        if (
          field.settings?.required === true &&
          !data[field.key] &&
          data[field.key] !== 0
        ) {
          validationErrors[field.key] = true;
        }
      });
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const {
      [scoreField?.key]: score,
      [commentField?.key]: comment,
      ...restData
    } = data;

    if (scoreField) {
      restData.score = score;
    }
    if (commentField) {
      restData.comment = comment;
    }
    onSend(restData);
  };

  const onChange = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const renderField = (field) => {
    switch (field.type) {
      case 'score':
        return (
          <Score
            field={field}
            onChange={onChange}
            values={data}
            errors={errors}
          />
        );
      case 'text':
        return (
          <Text
            field={field}
            onChange={onChange}
            values={data}
            errors={errors}
          />
        );
      case 'select':
        return (
          <Select
            field={field}
            onChange={onChange}
            values={data}
            errors={errors}
          />
        );
      default:
        return '';
    }
  };

  return (
    <Box
      p={2}
      component="form"
      onSubmit={handleSend}
      noValidate
      autoComplete="off"
      height="100%"
    >
      <Typography
        variant="h6"
        color="primary"
        aria-label={translations.question}
      >
        {fieldsArray[0].translations.text ||
          translations.pages[pages[pageIndex].key].title}
      </Typography>
      {fieldsArray.map((field) => (
        <Box key={field.key} my={2} className={`field-${field.key}`}>
          {renderField(field)}
        </Box>
      ))}
      {hasContactRequest && (
        <Box className={classes.contactRequestContainer}>
          <ContactRequest
            field={{
              translations: { text: translations.contact_request.text },
            }}
            onChange={onChange}
            values={data}
          />
        </Box>
      )}
      <Box>
        <Button
          className={classes.submitButton}
          fullWidth
          size="large"
          variant="contained"
          type="submit"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            translations.buttons.submit
          )}
        </Button>
      </Box>
      {hasPrivacyPolicy && (
        <Box mt={1} textAlign="center">
          <Link
            variant="caption"
            href={translations.privacy_policy.button_url}
            aria-label={translations.privacy_policy.text}
            target="_blank"
            rel="noopener"
          >
            {translations.privacy_policy.text}
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default Form;
