import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import SurveyWrapper from './components/Wrapper';
import StaticPage from './components/StaticPage';
import SurveyPreview from './components/Preview';

const container = global.document.getElementById('surveyFormRoot');

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/preview" exact element={<SurveyPreview />} />
        <Route path="/:formId" element={<SurveyWrapper />} />
        <Route path="/:formId/*" element={<SurveyWrapper />} />
        <Route path="*" element={<StaticPage />} />
      </Routes>
    </Router>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
