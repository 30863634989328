import { lumoaUiHost } from '../config';

export const getScoreLevel = (score, scoreField) => {
  if (!scoreField) {
    return 'open_ended';
  }
  let result = '';
  if (score <= scoreField.maxNeg) {
    result = 'detractor';
  } else if (score > scoreField.maxNeg && score < scoreField.minPos) {
    result = 'passive';
  } else {
    result = 'promoter';
  }
  return result;
};

export const getScoreField = (config) => {
  return config.survey.pages
    .sort((a, b) => a.order - b.order)[0]
    .fields.find((f) => f.type === 'score');
};

export const getCommentField = (config) => {
  return config.survey.pages
    .sort((a, b) => a.order - b.order)[0]
    .fields.find((f) => f.type === 'text');
};

export const getUrlParamsObject = (entries) => {
  const result = Array.from(entries, ([key, value]) => {
    return [key, value];
  }).reduce((obj, [key, value]) => {
    // eslint-disable-next-line no-param-reassign
    obj[key] = value;
    return obj;
  }, {});
  return result;
};

export const updateFrameHeight = () => {
  if (global.parent?.postMessage) {
    const height = document.getElementById('lumoaSurveyWrapper')?.scrollHeight;
    if (height) {
      global.parent.postMessage(height, lumoaUiHost);
    }
  }
};

export const getLanguage = (query, config) => {
  const browserLang = (
    navigator.languages
      ? navigator.languages[0]
      : navigator.language || navigator.userLanguage
  ).slice(0, 2);
  if (config?.survey?.languages) {
    const languages = config?.survey?.languages;
    if (languages.includes(query.lang)) {
      return query.lang;
    }
    if (languages.includes(browserLang)) {
      return browserLang;
    }
    return config.survey.defaultLanguage || 'en';
  }

  return query.lang || browserLang || 'en';
};
