import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, makeStyles, Paper } from '@material-ui/core';
import { lumoaFeedbackSend } from '../utils/api';
import {
  getUrlParamsObject,
  updateFrameHeight,
  getLanguage,
} from '../utils/helpers';
import { lumoaUiHost } from '../config';
import Form from './Form';
import Success from './Success';
import LumoaLogo from '../../public/lumoa.png';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  image: {
    maxWidth: '80%',
    maxHeight: '150px',
  },
}));

const useFormContainerStyles = makeStyles((theme) => ({
  root: (props) => ({
    backgroundColor: theme.palette.form,
    margin: props.previewMode ? theme.spacing(4) : 0,
  }),
}));

const Survey = ({ config, previewMode = false, step = null, stepChanged }) => {
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState();
  const [formData, setFormData] = useState([]);
  const location = useLocation();
  const classes = useStyles({ previewMode });
  const formContainerClasses = useFormContainerStyles({ previewMode });
  const query = getUrlParamsObject(
    new URLSearchParams(location.search).entries(),
  );
  const lang = getLanguage(query, config);
  const {
    id,
    survey: { translations, logo },
  } = config;

  const logoAltText =
    logo && logo.image_alt_text && logo.image_alt_text !== ''
      ? logo.image_alt_text
      : 'Logo';

  const logoSrc =
    previewMode && logo?.file
      ? URL.createObjectURL(logo.file)
      : logo?.url || '';

  const handleSendForm = async (data) => {
    setFormData(data);
    if (previewMode) {
      setSuccess(true);
      global.parent?.postMessage({ step: 'success' }, lumoaUiHost);
      // tell our preview component as well, else our next message will set state to same as old value = no update
      stepChanged('success');
      return;
    }
    setLoading(true);
    const allowUpdate = !!(
      config.feedback_update && config.feedback_update.enabled
    );
    const {
      score,
      comment,
      contactRequest,
      contactEmail,
      contactPhone,
      externalId,
      ...rest
    } = data;

    const tags = Object.entries(rest).map((entry) => {
      return {
        name: entry[0],
        value: entry[1],
      };
    });

    tags.push({
      name: 'campaign',
      value: id,
    });

    if (!isEmpty(config.backgroundTags)) {
      config.backgroundTags.forEach((tag) => {
        tag.values.forEach((value) => {
          tags.push({
            name: tag.name,
            value,
          });
        });
      });
    }

    let scoreToSend = score;
    if (!scoreToSend && scoreToSend !== 0) {
      scoreToSend = 7;
    }

    try {
      await lumoaFeedbackSend(
        `survey?updateIfExists=${allowUpdate}`,
        {
          score: scoreToSend,
          comment,
          tags,
          contactRequest,
          contactDetails: contactEmail || contactPhone,
          externalId,
        },
        { campaign: config.id },
      );
      setSuccess(true);
    } catch (e) {
      setSuccess(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (step !== 'success') {
      setFormData([]);
    }
    setSuccess(step === 'success');
  }, [step]);

  useEffect(() => {
    if (previewMode) updateFrameHeight();
  });

  return (
    <Box className={classes.mainContainer}>
      <Box
        height="100%"
        width={previewMode ? '90%' : '350px'}
        id="lumoaSurveyWrapper"
      >
        {config && logo?.url && (
          <Box my={3} textAlign="center">
            <img
              aria-label={logoAltText}
              alt={logoAltText}
              src={logoSrc}
              onLoad={() => previewMode && updateFrameHeight()}
              width={logo.width}
              className={classes.image}
            />
          </Box>
        )}
        {config && (
          <Paper
            classes={formContainerClasses}
            elevation={3}
            aria-atomic="true"
          >
            {success === true && formData ? (
              <Success
                values={formData}
                translations={translations}
                config={config}
                lang={lang}
              />
            ) : (
              <Form
                translations={translations}
                onSend={handleSendForm}
                config={config}
                loading={loading}
              />
            )}
          </Paper>
        )}
        <Box textAlign="center" mt={1.5} pb={previewMode ? 4 : 0}>
          <a href="https://lumoa.me" target="_blank" rel="noopener noreferrer">
            <img alt="lumoa-logo" src={LumoaLogo} width="162" />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default Survey;
