import {
  Box,
  FormGroup,
  Typography,
  Button,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import ScoreValue from './ScoreValue';
import { getScoreLevel } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  scoreLabel: {
    cursor: 'pointer',
    margin: 0,
    minWidth: 'unset',
    padding: theme.spacing(0.5),
  },
  scoreValue: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.score_border}`,
    backgroundColor: theme.palette.form,
    width: '38px',
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    fontWeight: 'bold',
    fontSize: '18px',
  },
  textarea: {
    border: '1px solid rgba(0,0,0,0.32)',
  },
  active: {
    color: theme.palette.form,
    backgroundColor: theme.palette.primary.main,
  },
}));

const MAX_ELEMENTS_IN_ROW = 6;
const MAX_REMINDER = 3;
const PIXELS_PER_MARK_ELEMENT = 52;

const getMarksContainerWidth = (scale) => {
  // COUNT OF ELEMENTS TO DISPLAY
  const countOfElements = scale.maxScale - scale.minScale + 1;
  // MAX ELEMENTS IN A ROW
  let divider = MAX_ELEMENTS_IN_ROW;
  const reminder = countOfElements % divider;
  // IF REMINDER LESS THAN MAX_REMINDER THEN WE SHOULD SPLIT RANGE DIFFERENTLY
  if (reminder < MAX_REMINDER) divider -= MAX_REMINDER - reminder;
  // CALCULATE NEEDED AMOUNT OF SPACE.
  return divider * PIXELS_PER_MARK_ELEMENT;
};

const getScale = (field) => {
  return {
    maxScale: field.highValue,
    minScale: field.lowValue,
  };
};

const getRange = (field) => {
  const ans = [];
  for (let i = field.lowValue; i <= field.highValue; i++) {
    ans.push(i);
  }
  return ans;
};

const Score = ({ field, onChange, values, errors }) => {
  const classes = useStyles();
  const { translations } = field;
  const scale = getScale(field);
  const range = getRange(field);
  const width = getMarksContainerWidth(scale);
  const isError = errors[field.key];

  const handleManualScoreChange = (e, score) => {
    if (e && e.key === 'Enter') {
      onChange(field.key, score);
    }
  };

  return (
    <FormGroup>
      <Box mt={2} display="flex" justifyContent="start">
        <Typography
          variant="body2"
          component="span"
          color="textPrimary"
          aria-label={translations.score_min}
        >
          {field.lowValue}
          {' - '}
          {translations.score_min}
        </Typography>
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={width}
          flexWrap="wrap"
          margin="auto"
        >
          {range.map((value) => {
            const isSelected = Number(values[field.key]) === value;
            const scoreValue = getScoreLevel(value, field);
            return (
              <Button
                className={classes.scoreLabel}
                onKeyUp={(e) => handleManualScoreChange(e, value)}
                onClick={() => onChange(field.key, value)}
                key={value}
                aria-pressed={isSelected}
                aria-live="assertive"
                data-testid={`score-${value}`}
              >
                <ScoreValue
                  value={value}
                  level={scoreValue}
                  selected={isSelected}
                />
              </Button>
            );
          })}
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Typography
          variant="body2"
          component="span"
          color="textPrimary"
          aria-label={translations.score_max}
        >
          {field.highValue}
          {' - '}
          {translations.score_max}
        </Typography>
      </Box>
      {Number.isNaN(Number(values[field.key])) && isError && (
        <FormHelperText
          error
          role="alert"
          aria-label={
            field.translations.required || 'Selecting a score is required'
          }
          aria-relevant="additions"
          data-testid="score-error"
        >
          {field.translations.required || 'Selecting a score is required'}
        </FormHelperText>
      )}
    </FormGroup>
  );
};

export default Score;
